import { ButtonGroup, TextInput } from "blixify-ui-web/lib";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { States } from "../../models/Admin";
import { PreferredTime, PreferrredDay, Status } from "../../models/Schedules";
import Select, { Option } from "../base/Select";
import SmartSelect from "../base/SmartSelect";
import Table, { TableHeader } from "../base/Table";
import { getJobsWithPagination } from "../store/actions/jobActions";
import {
  getScheduleWithPagination,
  schedulesDataLimit,
} from "../store/actions/schedulesAction";
import { authStateInterface } from "../store/reducers/authReducer";
import { scheduleStateInterface } from "../store/reducers/scheduleReducer";
import { userStateInterface } from "../store/reducers/usersReducer";

interface Props {
  history: any;
  pageIndex: number;
  authStore: authStateInterface;
  userStore: userStateInterface;
  scheduleStore: scheduleStateInterface;
  userId: string;
  userPhone: string;
  scheduleState: keyof typeof Status | undefined;
  agentView?: boolean;
  agentJobGroup?: string;
  handleAgentJobGroup?: (id: string) => void;
  handleUpdatePageIndex: (pageIndex: number) => void;
  getJobsWithPagination: (
    paginationStartAfter: string | Date,
    promoCode: string,
    assignedId: string
  ) => void;
  getScheduleWithPagination: (
    paginationStartAt: string | Date,
    userId: string,
    state: (keyof typeof States)[],
    paginationType?: keyof typeof Status
  ) => void;
  handleUpdateStatusFilter: (statusGroupType: string) => void;
  handleFilter: (id: string, key: string) => void;
}

class Schedules extends Component<Props> {
  handleSelectedData = (id: string) => {
    if (this.props.agentView) {
      this.props.history.push(`/dashboard/jobEditor?data=${id}`);
    } else {
      this.props.history.push(`/dashboard/scheduleEditor?data=${id}`);
    }
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      //INFO : Check whether need to get new data from firebase
      if (
        this.props.scheduleStore.lastCursor &&
        this.props.pageIndex * schedulesDataLimit + 1 >
          this.props.scheduleStore.schedules.length
      ) {
        if (this.props.agentView) {
          if (this.props.agentJobGroup === "available") {
            this.props.getJobsWithPagination(
              this.props.scheduleStore.lastCursor,
              this.props.authStore.user?.promo ?? "",
              ""
            );
          } else {
            this.props.getJobsWithPagination(
              this.props.scheduleStore.lastCursor,
              "",
              this.props.authStore.user?.id ?? ""
            );
          }
        } else {
          this.props.getScheduleWithPagination(
            this.props.scheduleStore.lastCursor,
            this.props.userId,
            this.props.authStore.user?.state ?? [],
            this.props.scheduleState
          );
        }
      }
      this.props.handleUpdatePageIndex(this.props.pageIndex + 1);
    } else {
      this.props.handleUpdatePageIndex(this.props.pageIndex - 1);
    }
  };

  handleFilterStatus = (id: string, key: string) => {
    this.props.handleUpdateStatusFilter(key);
  };

  renderStatusOptions = () => {
    const statusOptions: Option[] = [];
    if (this.props.scheduleState) {
      statusOptions.push({
        key: "",
        title: "Clear Selection",
      });
    }
    Object.keys(Status).map((eachStatusKey) => {
      statusOptions.push({
        key: eachStatusKey,
        title: Status[eachStatusKey as keyof typeof Status],
      });
      return null;
    });

    return statusOptions;
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (this.props.scheduleStore.schedules?.length > 0) {
      this.props.scheduleStore.schedules.map((eachSchedule) =>
        this.props.userStore.users.map((eachUser) => {
          if (eachUser.id === eachSchedule.userId) {
            listView.push({
              id: eachSchedule.id,
              updatedAt: moment(eachSchedule.updatedAt.seconds * 1000).format(
                "DD/MM/YY"
              ),
              email: eachUser.email ? eachUser.email : eachUser.mobileNo,
              phone: eachSchedule.phone,
              address: eachSchedule.address.name,
              status: Status[eachSchedule.status],
              preferrredDay: PreferrredDay[eachSchedule.preferredDay],
              preferrredTime: PreferredTime[eachSchedule.preferredTime],
              estimatedWeight: `${eachSchedule.estimatedWeight} kg`,
            });
          }
          return "";
        })
      );
    }
    return listView;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "updatedAt",
        title: "Date",
      },
      {
        key: "status",
        title: "Status",
      },
      {
        key: "email",
        title: "Email",
      },
      {
        key: "address",
        title: "Address",
      },
      {
        key: "phone",
        title: "Phone",
      },
      {
        key: "preferrredDay",
        title: "Preferred Day",
      },
      {
        key: "preferrredTime",
        title: "Preferred Time",
      },
      {
        key: "estimatedWeight",
        title: "Estimated Weight",
      },
    ];
    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="  sm:flex sm:items-center sm:justify-between">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Schedule List
            </h3>

            <div className="sm:flex sm:flex-row">
              {this.props.agentView ? (
                <ButtonGroup
                  selection={[
                    { id: "available", name: "Available" },
                    { id: "assigned", name: "Assigned" },
                  ]}
                  onClick={this.props.handleAgentJobGroup}
                  selectedId={this.props.agentJobGroup ?? ""}
                />
              ) : (
                <>
                  <div className="mt-3 w-30 sm:mr-4 sm:mt-1 sm:w-60">
                    <SmartSelect
                      id="email"
                      placeholder="Search User's Email/Mobile"
                      value={this.props.userId}
                      error={""}
                      onChange={this.props.handleFilter}
                    />
                  </div>

                  <div className="mt-3 w-30 sm:mr-4 sm:mt-0 sm:w-60">
                    <TextInput
                      id="phone"
                      placeholder="+60123456789"
                      label=""
                      value={this.props.userPhone}
                      type="mobile"
                      onChange={(e) => {
                        const id = e.target.id;
                        const value = e.target.value;
                        this.props.handleFilter(id, value);
                      }}
                    />
                  </div>

                  <div className="w-30 mt-3 sm:mt-0 sm:w-60">
                    <Select
                      id="selectedStatus"
                      placeholder="Select the status"
                      value={this.props.scheduleState ?? ""}
                      options={this.renderStatusOptions()}
                      onChange={this.handleFilterStatus}
                      error={""}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <Table
          loading={this.props.scheduleStore.loading}
          header={headers}
          pageIndex={this.props.pageIndex}
          lastCursor={this.props.scheduleStore.lastCursor}
          data={this.renderFilteredData()}
          dataLimit={schedulesDataLimit}
          onClickRow={this.handleSelectedData}
          handlePage={this.handlePagination}
          newTabUrl={
            this.props.agentView
              ? "/dashboard/jobEditor?data="
              : "/dashboard/scheduleEditor?data="
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    userStore: state.userStore,
    scheduleStore: state.scheduleStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getJobsWithPagination: (
      paginationStartAfter: string | Date,
      promoCode: string,
      assignedId: string
    ) =>
      dispatch(
        getJobsWithPagination(paginationStartAfter, promoCode, assignedId)
      ),
    getScheduleWithPagination: (
      paginationStartAt: string | Date,
      userId: string,
      state: (keyof typeof States)[],
      paginationType?: keyof typeof Status
    ) =>
      dispatch(
        getScheduleWithPagination(
          paginationStartAt,
          userId,
          state,
          paginationType
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedules);
