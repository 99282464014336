import axios from "axios";
import moment from "moment";
import Ngo from "../../../models/Ngo";
import { PendingTransactionModel } from "../../../models/PendingTransaction";
import PromotionModel, { PromotionType } from "../../../models/Promotion";
import { ReferralModel } from "../../../models/Referral";
import Reward from "../../../models/Reward";
import { ScheduleModel } from "../../../models/Schedules";
import { TransactionModel, TransactionType } from "../../../models/Transaction";
import { PromotionApplied, UserModel } from "../../../models/User";
import {
  TransactionEditorError,
  TransactionEditorState,
} from "../../dashboard/TransactionEditor";
import firebase from "../../utils/firebase";

export const transactionsDataLimit: number = 10;

export const handleTransactionCondition = (
  transactionState: TransactionEditorState,
  transactionError: TransactionEditorError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "email":
        /* eslint-disable */
        const filter =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        /* eslint-enable */
        if (!filter.test(transactionState.email.replace(/\s/g, ""))) {
          transactionError["emailError"] =
            "Please enter your email in the correct format";
        } else {
          transactionError["emailError"] = "";
        }
        break;
      case "amount":
        if (Number(transactionState.amount) > 0) {
          transactionError["amountError"] = "";
        } else {
          transactionError["amountError"] = "Please enter a positive amount";
        }
        break;
      case "date":
        if (transactionState.date.replace(/\s/g, "").length <= 0) {
          transactionError["dateError"] = "Please enter the date";
        } else if (
          moment(transactionState.date, "DD-MM-YYYY").format("DD-MM-YYYY") !==
          transactionState.date
        ) {
          transactionError["dateError"] =
            "Please enter the date with the format of date DD-MM-YYYY (eg 30-05-2021)";
        } else {
          transactionError["dateError"] = "";
        }
        break;
    }
    return null;
  });
};

export const getTransactionWithPagination = (
  paginationStartAfter: string | Date,
  paginationType: keyof typeof TransactionType,
  selectedUser: string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let baseQuery = firebase
        .firestore()
        .collection("transactions")
        .where("type", "==", paginationType);

      if (selectedUser) {
        baseQuery = baseQuery.where("userId", "==", selectedUser);
      }

      const transactionsCollectionQuery = baseQuery
        .orderBy("date", "desc")
        .limit(transactionsDataLimit);

      let transactionsList: TransactionModel[] =
        getState().transactionStore.transactions;
      const newTransactionList: TransactionModel[] = [];
      const usersList: UserModel[] = getState().userStore.users;

      if (!paginationStartAfter) {
        transactionsList = [];
      }

      updateTransactionLoadingState(dispatch, true);

      let transactionsSnapshot;
      if (paginationStartAfter) {
        transactionsSnapshot = await transactionsCollectionQuery
          .startAfter(paginationStartAfter)
          .get();
      } else {
        transactionsSnapshot = await transactionsCollectionQuery.get();
      }

      if (transactionsSnapshot) {
        const userIdList: string[] = [];
        const scheduleIdList: string[] = [];
        transactionsSnapshot.forEach((eachDoc: any) => {
          const eachTransaction = eachDoc.data() as TransactionModel;
          newTransactionList.push(eachTransaction);
          if (!scheduleIdList.includes(eachTransaction.scheduleId)) {
            scheduleIdList.push(eachTransaction.scheduleId);
          }

          let userPresent = false;
          usersList.map((eachUser) => {
            if (eachUser.id === eachTransaction.userId) {
              userPresent = true;
            }
            return null;
          });

          if (!userPresent) {
            if (!userIdList.includes(eachTransaction.userId)) {
              userIdList.push(eachTransaction.userId);
            }
          }
        });

        const newUserList: UserModel[] = [];
        let lastCursor: Date | string = "";

        if (newTransactionList.length > 0) {
          if (newTransactionList.length === transactionsDataLimit) {
            lastCursor = newTransactionList[transactionsDataLimit - 1].date;
          }

          dispatch({
            type: "UPDATE_TRANSACTIONS",
            payload: {
              transactions: transactionsList.concat(newTransactionList),
              lastCursor: lastCursor,
            },
          });
        } else {
          dispatch({
            type: "UPDATE_TRANSACTIONS",
            payload: {
              transactions: transactionsList,
              lastCursor: lastCursor,
            },
          });
        }

        if (userIdList.length > 0) {
          while (userIdList.length > 0) {
            const spliceUserIdList = userIdList.splice(
              0,
              transactionsDataLimit
            );
            const userSnapshot = await firebase
              .firestore()
              .collection("users")
              .where("id", "in", spliceUserIdList)
              .get();
            if (userSnapshot) {
              userSnapshot.forEach((eachUser: any) => {
                const eachUserData = eachUser.data() as UserModel;
                newUserList.push(eachUserData);
              });
            }
          }

          dispatch({
            type: "UPDATE_USER_LIST",
            payload: {
              users: usersList.concat(newUserList),
            },
          });
        }

        const newScheduleList: ScheduleModel[] = [];
        const schedulesList: ScheduleModel[] =
          getState().scheduleStore.schedules;
        if (scheduleIdList.length > 0) {
          while (scheduleIdList.length > 0) {
            const spliceScheduleIdList = scheduleIdList.splice(
              0,
              transactionsDataLimit
            );
            const schedulesSnapshot = await firebase
              .firestore()
              .collection("schedules")
              .where("id", "in", spliceScheduleIdList)
              .get();
            if (schedulesSnapshot) {
              schedulesSnapshot.forEach((eachSchedule: any) => {
                const eachScheduleData = eachSchedule.data() as ScheduleModel;
                newScheduleList.push(eachScheduleData);
              });
            }
          }

          dispatch({
            type: "UPDATE_SCHEDULES_LIST",
            payload: {
              schedules: schedulesList.concat(newScheduleList),
            },
          });
        }
      }
      updateTransactionLoadingState(dispatch, false);
    } catch (err: any) {
      updateTransactionLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const clearTransaction = () => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_TRANSACTIONS",
      payload: {
        transactions: [],
        lastCursor: "",
      },
    });
  };
};

export const updateTransactionTrigger = (trigger: boolean) => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_TRANSACTION_TRIGGER",
      payload: {
        trigger: trigger,
      },
    });
  };
};

export const updateSelectedTransaction = (
  user: UserModel,
  transaction: TransactionModel
) => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_SELECTED_TRANSACTION",
      payload: {
        selectedTransaction: {
          user,
          transaction,
        },
      },
    });
  };
};

const updateTransactionLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_TRANSACTIONS_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const createCreditTransaction = async (
  updateBalance: boolean,
  email: string,
  amount: number,
  date: string,
  type: "id" | "email",
  paymentMethod: any
) => {
  try {
    let userSnapshot: any = "";
    if (type === "email") {
      userSnapshot = await firebase
        .firestore()
        .collection("users")
        .where("email", "==", email)
        .get();
    } else {
      userSnapshot = await firebase
        .firestore()
        .collection("users")
        .where("id", "==", email)
        .get();
    }

    let selectedUser: UserModel | null = null;
    if (userSnapshot) {
      userSnapshot.forEach((doc: any) => {
        selectedUser = doc.data() as UserModel;
      });
    }

    if (selectedUser) {
      const filteredUser: UserModel = selectedUser as UserModel;
      const transactionRef = firebase
        .firestore()
        .collection("transactions")
        .doc();
      const newTransactionId = transactionRef.id;
      const transactionModel: TransactionModel = {
        id: newTransactionId,
        type: "CREDIT",
        date: moment(date, "DD-MM-YYYY").toDate(),
        userId: filteredUser.id,
        scheduleId: "",
        receipt: "",
        amount: amount,
        paymentMethod: paymentMethod,
      };
      if (updateBalance) {
        if (filteredUser.balance && filteredUser.balance >= amount) {
          const currentBalance = filteredUser.balance;
          const newBalance = Number((currentBalance - amount).toFixed(2));
          await firebase
            .firestore()
            .collection("users")
            .doc(filteredUser.id)
            .update({
              balance: newBalance,
            });
          await transactionRef.set(transactionModel);
        } else {
          return "Insufficient user's account balance";
        }
      } else {
        await transactionRef.set(transactionModel);
      }
    } else {
      return "User cannot be found";
    }
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const createTNGPendingTransaction = async (
  email: string,
  amount: number,
  recycleKg: number,
  date: string
) => {
  try {
    let userSnapshot = await firebase
      .firestore()
      .collection("users")
      .where("id", "==", email)
      .get();
    let selectedUser: UserModel | null = null;
    if (userSnapshot) {
      userSnapshot.forEach((doc: any) => {
        selectedUser = doc.data() as UserModel;
      });
    }
    if (selectedUser) {
      const filteredUser: UserModel = selectedUser as UserModel;
      const transactionRef = firebase
        .firestore()
        .collection("pendTransactions")
        .doc();
      const newTransactionId = transactionRef.id;
      const transactionModel: PendingTransactionModel = {
        id: newTransactionId,
        date: moment(date, "DD-MM-YYYY").toDate(),
        userId: filteredUser.id,
        amount: amount,
        tng: true,
        tngRecycleKg: recycleKg,
      };
      await transactionRef.set(transactionModel);
    } else {
      return "User cannot be found";
    }
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const createTransaction = async (
  scheduleId: string,
  userId: string,
  amount: number
) => {
  try {
    const transactionRef = firebase
      .firestore()
      .collection("transactions")
      .doc();

    const newTransactionId = transactionRef.id;

    const currentDate = moment();
    currentDate.add(1, "minute");

    const transactionModel: TransactionModel = {
      id: newTransactionId,
      type: "DEBIT" as keyof typeof TransactionType,
      date: currentDate.toDate(),
      userId: userId,
      scheduleId: scheduleId,
      receipt: "",
      amount: amount,
    };
    transactionRef.set(transactionModel);
    return "";
  } catch (err: any) {
    return err.message;
  }
};

//INFO: Get Specific transaction data with schedule id
export const getTransactionWithScheduleId = async (scheduleId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && scheduleId) {
      const transactionsSnapshot = await firebase
        .firestore()
        .collection("transactions")
        .where("scheduleId", "==", scheduleId)
        .get();
      let selectedTransaction: TransactionModel | null = null;
      if (transactionsSnapshot) {
        transactionsSnapshot.forEach((doc: any) => {
          selectedTransaction = doc.data() as TransactionModel;
        });

        return selectedTransaction;
      }
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const getTransaction = async (selectedId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      const transactionQuery = await firebase
        .firestore()
        .collection("transactions")
        .doc(selectedId)
        .get();

      if (transactionQuery.exists) {
        const currentTransaction = transactionQuery.data() as TransactionModel;
        const userQuery = await firebase
          .firestore()
          .collection("users")
          .doc(currentTransaction.userId)
          .get();

        let ngoQuery;
        let rewardQuery;
        if (currentTransaction.doneeId) {
          ngoQuery = await firebase
            .firestore()
            .collection("ngos")
            .doc(currentTransaction.doneeId)
            .get();
        } else if (currentTransaction.rewardId) {
          rewardQuery = await firebase
            .firestore()
            .collection("rewards")
            .doc(currentTransaction.rewardId)
            .get();
        }

        if (userQuery.exists) {
          return {
            transaction: currentTransaction,
            user: userQuery.data() as UserModel,
            ngo: ngoQuery?.exists ? (ngoQuery.data() as Ngo) : null,
            reward: rewardQuery?.exists ? (rewardQuery.data() as Reward) : null,
          };
        } else {
          return "User cannot be found";
        }
      } else {
        return "Appointment cannot be found";
      }
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

interface Promotion {
  type: keyof typeof PromotionType;
  increase: number;
}

export const calculatePayment = async (
  userId: string,
  weight: number,
  promotions: PromotionApplied[],
  tngSegment?: "Business" | "Household"
) => {
  let total = 0;
  const promotionApplied: Promotion = {
    type: "GENERIC",
    increase: 0,
  };

  if (promotions.length > 0) {
    const selectedPromotion = promotions[promotions.length - 1];
    const promotionQuery = await firebase
      .firestore()
      .collection("promotion")
      .doc(selectedPromotion.id)
      .get();

    if (promotionQuery.exists) {
      const currentPromotion = promotionQuery.data() as PromotionModel;
      const promotionAppliedDate = moment(
        selectedPromotion.appliedDate.seconds * 1000
      );
      promotionAppliedDate.add(
        currentPromotion.durationAppliedInMonths,
        "month"
      );

      if (moment().isSameOrBefore(promotionAppliedDate)) {
        if (currentPromotion.type === "FLAT") {
          promotionApplied["type"] = "FLAT";
          promotionApplied["increase"] = Number(
            currentPromotion.fixedPriceRate
          );
        } else {
          promotionApplied["type"] = "GENERIC";
          promotionApplied["increase"] =
            (100 + Number(currentPromotion.increasePercentRate)) / 100;
        }
      }
    }
  }

  if (promotionApplied.increase === 0) {
    const referExist = await checkReferralExist(userId);
    if (referExist) {
      promotionApplied["type"] = "GENERIC";
      promotionApplied["increase"] = 1.1;
    }
  }

  const userQuery = await firebase
    .firestore()
    .collection("users")
    .doc(userId)
    .get();
  let userData: UserModel | undefined;
  if (userQuery.exists) {
    userData = userQuery.data() as UserModel;
  }

  if (promotionApplied.type === "FLAT") {
    total = weight * promotionApplied.increase;
  } else if (userData?.enterprise && userData.enterpriseStatus === "ACC") {
    if (weight < 50) {
      total = weight * 2.6;
    } else if (weight < 100) {
      total = weight * 2.8;
    } else if (weight < 500) {
      total = weight * 3;
    } else if (weight >= 500) {
      total = weight * 3.2;
    }
  } else if (
    promotionApplied.type === "GENERIC" &&
    promotionApplied.increase > 0
  ) {
    if (weight < 10) {
      total = weight * (1.3 * promotionApplied.increase);
    } else if (weight < 50) {
      total = weight * (1.6 * promotionApplied.increase);
    } else if (weight < 100) {
      total = weight * (1.9 * promotionApplied.increase);
    } else if (weight >= 100) {
      total = weight * (2.2 * promotionApplied.increase);
    }
  } else {
    if (weight < 10) {
      total = weight * 1.3;
    } else if (weight < 50) {
      total = weight * 1.6;
    } else if (weight < 100) {
      total = weight * 1.9;
    } else if (weight >= 100) {
      total = weight * 2.2;
    }
  }

  if (tngSegment) {
    if (weight < 30) total = weight * 2.5;
    else if (weight < 100) total = weight * 3;
    else total = weight * 3.5;
  }

  return Number(total.toFixed(2));
};

export const activateReferral = async (user: UserModel) => {
  try {
    const referralSnapshot = await firebase
      .firestore()
      .collection("referrals")
      .where("activated", "==", false)
      .where("refereeUserId", "==", user.id)
      .orderBy("activationDate", "desc")
      .limit(1)
      .get();

    if (referralSnapshot) {
      let eachReferral: ReferralModel | undefined;
      referralSnapshot.forEach((eachDoc: any) => {
        eachReferral = eachDoc.data() as ReferralModel;
      });

      if (eachReferral) {
        await firebase
          .firestore()
          .collection("referrals")
          .doc(eachReferral.id)
          .update({ activated: true });

        const referralUserQuery = await firebase
          .firestore()
          .collection("users")
          .doc(eachReferral.referralUserId)
          .get();
        if (referralUserQuery.exists) {
          const referralUser = referralUserQuery.data() as UserModel;
          const refereeContent = `Hi ${user.name},<br><br>Congratulations on recycling your used cooking oil for the first time at Arus Oil. Since you have been referred by ${referralUser.name}, both of you are entitled to a 10% increase in the purchasing rate of your used cooking oil for 3 months. Congratulations once again and keep recycling with Arus Oil !<br><br>Thanks,<br>Arus Oil`;
          const referralContent = `Hi ${referralUser.name},<br><br>Your friend, ${user.name} has recycled his/her used cooking oil for the first time at Arus Oil. As a result, both of you are entitled to a 10% increase in the purchasing rate of your used cooking oil for 3 months. Congratulations once again and keep recycling with Arus Oil !<br><br>Thanks,<br>Arus Oil`;

          await axios.post(
            "https://api.enginemailer.com/RESTAPI/Submission/SendEmail",
            {
              UserKey: process.env.REACT_APP_EMAIL_SEND_API,
              ToEmail: user.email,
              SenderEmail: "noreply@arusoil.com",
              SenderName: "Arus Oil",
              Subject: "[Arus Oil] Entitlement of 10% Increase From Referral",
              SubmittedContent: refereeContent,
            }
          );
          await axios.post(
            "https://api.enginemailer.com/RESTAPI/Submission/SendEmail",
            {
              UserKey: process.env.REACT_APP_EMAIL_SEND_API,
              ToEmail: referralUser.email,
              SenderEmail: "noreply@arusoil.com",
              SenderName: "Arus Oil",
              Subject: "[Arus Oil] Entitlement of 10% Increase From Referral",
              SubmittedContent: referralContent,
            }
          );
        }
      }
    }
  } catch (err) {}
};

const checkReferralExist = async (userId: string) => {
  if (userId) {
    try {
      const referralSnapshot = await firebase
        .firestore()
        .collection("referrals")
        .where("activated", "==", true)
        .where("referralUserId", "==", userId)
        .orderBy("activationDate", "desc")
        .limit(1)
        .get();

      const refereeSnapshot = await firebase
        .firestore()
        .collection("referrals")
        .where("activated", "==", true)
        .where("refereeUserId", "==", userId)
        .orderBy("activationDate", "desc")
        .limit(1)
        .get();

      const referralList: ReferralModel[] = [];
      if (referralSnapshot) {
        referralSnapshot.forEach((eachDoc: any) => {
          const eachReferral = eachDoc.data() as ReferralModel;
          referralList.push(eachReferral);
        });
      }
      if (refereeSnapshot) {
        refereeSnapshot.forEach((eachDoc: any) => {
          const eachReferral = eachDoc.data() as ReferralModel;
          referralList.push(eachReferral);
        });
      }

      referralList.sort(function (a, b) {
        return moment(a.activationDate.seconds * 1000).isAfter(
          moment(b.activationDate.seconds * 1000)
        )
          ? -1
          : 0;
      });

      if (referralList.length > 0) {
        const selectedReferral = referralList[0];
        const promotionAppliedDate = moment(
          selectedReferral.activationDate.seconds * 1000
        );
        promotionAppliedDate.add(3, "month");
        if (moment().isSameOrBefore(promotionAppliedDate)) {
          return true;
        }
      }
      return false;
    } catch (err) {
      return false;
    }
  }
};
