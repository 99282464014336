import { Component } from "react";
import { connect } from "react-redux";
import Schedules from "../dashboard/Schedules";
import SchedulesEditor from "../dashboard/SchedulesEditor";
import { getJobsWithPagination } from "../store/actions/jobActions";
import { clearSchedule } from "../store/actions/schedulesAction";
import { clearUsers } from "../store/actions/usersActions";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  history: any;
  pathName: string;
  authStore: authStateInterface;
  updateScheduleModalVisible: boolean;
  updateScheduleModalType: string;
  confirmationModalVisible: boolean;
  handleOpenConfirmationModal: (visible: boolean) => void;
  handleOpenScheduleUpdateModal: (visible: boolean) => void;
  getJobsWithPagination: (
    paginationStartAfter: string | Date,
    promoCode: string,
    assignedId: string
  ) => void;
  clearSchedule: () => void;
  clearUsers: () => void;
}

interface State {
  pageIndex: number;
  jobGroup: string;
}

class JobController extends Component<Props> {
  state: State = {
    pageIndex: 1,
    jobGroup: "available",
  };

  componentDidMount() {
    this.handleGetPreviewData();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.pathName !== this.props.pathName) {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedId = searchParams.get("data");
      if (selectedId === "r") {
        this.setState({ pageIndex: 1, jobGroup: "available" });
        this.handleGetPreviewData();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearSchedule();
    this.props.clearUsers();
  }

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex });
  };

  handleGetPreviewData = async () => {
    this.handleUpdatePageIndex(1);
    this.props.clearSchedule();
    this.props.clearUsers();
    if (this.state.jobGroup === "available") {
      this.props.getJobsWithPagination(
        "",
        this.props.authStore.user?.promo ?? "",
        ""
      );
    } else {
      this.props.getJobsWithPagination(
        "",
        "",
        this.props.authStore.user?.id ?? ""
      );
    }
  };

  handleAgentJobGroup = (id: string) => {
    this.setState(
      {
        jobGroup: id,
      },
      () => {
        this.handleGetPreviewData();
      }
    );
  };

  render() {
    if (this.props.authStore.user?.role !== "AGENT") {
      return null;
    }

    if (this.props.pathName === "/dashboard/jobs") {
      return (
        <Schedules
          agentView={true}
          agentJobGroup={this.state.jobGroup}
          history={this.props.history}
          scheduleState={undefined}
          userId={""}
          userPhone=""
          pageIndex={this.state.pageIndex}
          handleUpdatePageIndex={this.handleUpdatePageIndex}
          handleUpdateStatusFilter={() => {}}
          handleFilter={() => {}}
          handleAgentJobGroup={this.handleAgentJobGroup}
        />
      );
    } else if (this.props.pathName === "/dashboard/jobEditor") {
      return (
        <SchedulesEditor
          agentView={true}
          history={this.props.history}
          updateAddressModalVisible={false}
          confirmationModalVisible={this.props.confirmationModalVisible}
          handleOpenConfirmationModal={this.props.handleOpenConfirmationModal}
          updateScheduleModalVisible={this.props.updateScheduleModalVisible}
          updateScheduleModalType={this.props.updateScheduleModalType}
          handleOpenScheduleUpdateModal={
            this.props.handleOpenScheduleUpdateModal
          }
          handleOpenAddressModal={() => {}}
        />
      );
    } else {
      return null;
    }
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getJobsWithPagination: (
      paginationStartAfter: string | Date,
      promoCode: string,
      assignedId: string
    ) =>
      dispatch(
        getJobsWithPagination(paginationStartAfter, promoCode, assignedId)
      ),
    clearSchedule: () => dispatch(clearSchedule()),
    clearUsers: () => dispatch(clearUsers()),
  };
};

export default connect(null, mapDispatchToProps)(JobController);
